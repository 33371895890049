.wrapper-first-time-visit {
    display: flex;
    justify-content: center;
    align-items: center;
}
.paper-first-time-visit {
    min-width: 768px;
    min-height: 541px;
    width: 60%;
    margin-top: 32px;
    position: relative;
}
.stepper-content {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.stepper-content-final-step {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.stepper-content-buttons {
    width: 90%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 5%;
    bottom: 16px;
}
.stepper-content-final-buttons {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 16px;
}
.first-time-visit-question {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 32px;
    font-size: 20px;
}
.first-time-visit-question-text {
    margin-right: 10px;
    width: 50%;
}
.first-time-visit-yes-no {
    width: 90%;
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
}
.first-time-visit-collapse {
    width: 90%;
    position: relative;
}
.first-time-visit-none {
    display: none;
}
.first-time-visit-radio-button .MuiTypography-body2-58 {
    font-size: 16px;
}
.first-time-visit-radio-button {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 40%;
}
.first-time-visit-mortgage-type {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 28px;
}
.first-time-visit-final-page {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    width: 90%;
    margin-top: 32px;
}
.first-time-visit-final-text-row {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
}
.first-time-visit-final-text {
    width: 50%;
}
.first-time-final-step {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.first-time-visit-input-help {
    position: absolute;
    right: 0;
    height: 34px;
    display: flex;
    align-items: center;
    width: 15%;
    justify-content: center;
}
.first-time-visit-step-title {
    text-align: center;
    white-space: normal !important;
    padding-bottom: 6px;
}
.first-time-visit-title-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.first-time-visit-step-info {
    width: 90%;
}
.first-time-visit-tooltip {
    position: absolute;
    right: 0px;
    width: 15%;
    text-align: center;
}
.first-time-visit-stepper{
    padding-bottom: 12px !important;
}
@media screen and (max-width: 767px) {
    .paper-first-time-visit {
        min-width: 0px;
        min-height: 0;
        width: 98%;
        margin-top: 2px;
        position: relative;
        margin-top: 6px;
    }
    .wrapper-first-time-visit {
        flex-direction: column;
        margin-top: 32px;
    }
    .first-time-visit-padding-under-paper {
        padding-bottom: 64px;
        width: 100%;
    }
    .first-time-visit-yes-no {
        flex-direction: column;
        align-items: unset;
    }
    .first-time-visit-radio-button {
        width: 100%;
    }
    .first-time-visit-final-result {
        display: flex;
        align-items: center;
    }
    .first-time-visit-final-text {
        padding-right: 5%;
        width: 60%;
    }
    .first-time-visit-question-text {
        width: 90%;
        margin-bottom: 12px;
    }
    .dialog-input > .input-field {
        width: 100%;
        justify-content: unset;
        display: flex;
        flex-direction: row;
    }
    .first-time-visit-mobile-stepper-paper {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
    .first-time-visit-mortgage-type {
        flex-direction: column;
        align-items: unset;
    }
    .first-time-visit-mortgage-type > .first-time-visit-radio-button {
        display: flex;
        align-items: center;
    }
}
