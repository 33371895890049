.bottom-selection-app-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    padding: 5px;
    padding-right: 20px;
    top: auto;
    background-color: gray;
    bottom: 0;
    z-index: 1003;
}
.side-menu-open-animation {
    transition: margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.side-menu-close-animation {
    transition: margin-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.bottom-selection-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.bottom-selection-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
