.dialog-input {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin: 8px 0;
}
.dialog-input .input-label {
    display: flex;
    width: 50%;
    /* height: 40px; */
    justify-content: flex-start;
    align-items: center;
}
.dialog-input .input-field {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 85%;
}
.dialog-input .input-field .line {
    margin-left: 23%;
}
.dialog-input .input-help {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
}
.dialog-input .input-error-desktop {
    z-index: 101;
    top: 35px;
    position: absolute;
    color: red;
}
.dialog-input .input-error-mobile {
    display: none;
}
.dialog-input .error {
    border-color: red;
    box-shadow: 0 0 0 1px red;
}
.dialog-input .error:focus,
.dialog-input .error:active {
    outline: none;
    box-shadow: 0 0 0 1.5px red !important;
    border: none;
}
.dialog-input .input-field input {
    /* min-height: 36px; */
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 8px;
    padding-bottom: 6px;
    font-size: 16px;
    /* height: 40px; */
}
.dialog-input .input-field input[type="date"] {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}
.dialog-input .input-field input:focus,
.dialog-input .input-field input:active {
    outline: none;
    box-shadow: 0 0 0 1.5px var(--app--color-primary);
    border-color: lightgray !important;
    /* border: none; */
}
.dialog-input .input-field input:hover {
    border-color: black;
}
.dialog-input .error:hover {
    border-color: red !important;
}
.dialog-input .input-field input::-webkit-inner-spin-button,
.dialog-input .input-field input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.dialog-input .input-field input[type="number"] {
    -moz-appearance: textfield;
}
.dialog-input .input-field input:read-only {
    outline: none;
    box-shadow: none;
    pointer-events: none;
    cursor: none;
    color: gray;
    border: none;
}
.dialog-input .radio-field > div {
    display: flex;
    flex-direction: row;
}
.dialog-input .radio-field {
    display: flex;
    width: 85%;
    justify-content: flex-start;
}
.dialog-input .radio-field span {
    font-size: 1em;
}
.dialog-input .input-help-wrapper {
    display: flex;
    width: 60%;
}
@media screen and (max-width: 767px) {
    .dialog-input {
        flex-direction: column;
    }
    .dialog-input .input-label {
        width: 100%;
        margin-bottom: 5px;
    }
    .dialog-input .input-field {
        width: 85%;
    }
    .dialog-input .input-help {
        width: 15%;
    }
    .dialog-input .input-help-wrapper {
        flex-direction: row;
        display: flex;
        margin: 10px 0;
        width: 100%;
    }
    .dialog-input .input-error-desktop {
        display: none;
    }
    .dialog-input .input-error-mobile {
        display: block;
        color: red;
    }
    .dialog-input .input-field .line {
        margin-left: 0;
    }
}
