.current-page {
    background-color: rgba(0, 0, 0, 0.05) !important;
}
.calculation-app-bar {
    top: 64px !important;
    background-color: rgb(68, 138, 255) !important;
    right: unset !important;
}
.calculation-tool-bar {
    min-height: 32px !important;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: white;
    background-color: rgb(68, 138, 255);
}
.calculation-bar-x-icon {
    padding: 4px !important;
}
.calculation-bar-tooltip {
    padding-top: 4px !important;
    position: fixed;
    right: 1%;
    color: white;
}
.calculation-bar-text{
    width: 100%;
}
.calculation-bar-typography{
    display: flex !important;
    justify-content: space-evenly;
}
.calculation-bar-x-button {
    position: fixed;
    right: 1%;
    color: white;
}
.calculation-empty-tool-bar{
    margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
    .calculation-app-bar{
        top: 56px !important;
    }
}
