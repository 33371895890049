@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?alacwy');
  src:  url('fonts/icomoon.eot?alacwy#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?alacwy') format('truetype'),
    url('fonts/icomoon.woff?alacwy') format('woff'),
    url('fonts/icomoon.svg?alacwy#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e906";
}
.icon-close:before {
  content: "\e903";
}
.icon-home:before {
  content: "\e902";
}
.icon-pencil:before {
  content: "\e905";
}
.icon-cog:before {
  content: "\e994";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-history:before {
  content: "\e94d";
}
.icon-search1:before {
  content: "\e986";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-facebook2:before {
  content: "\ea91";
}
