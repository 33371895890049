.history-day {
    display: flex;
    flex-direction: column;
    width: 96%;
    margin: 0 auto;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.history-day:last-child {
    margin-bottom: 40px;
}
.icon-check {
    color: #66bb6a;
}
.icon-warning {
    color: #f44336;
}
.history-table td,
.history-table th {
    padding-left: 0px;
    padding-right: 5px;
}
.history-table tr {
    height: 57px;
}
.history-table td:last-child {
    padding-right: 0 !important;
}
.history-table .smaller-item:first-child {
    width: 4%;
    padding: 5px 5px;
}
.history-table .small-item:first-child {
    padding: 5px 15px;
}
.history-table .smaller-item {
    width: 4%;
    padding: 5px 0px;
}
.history-table .small-item {
    width: 5%;
    padding: 5px 0px;
}
.history-table .medium-item {
    width: 10%;
    padding: 5px 0px;
    max-width: 100px;
}
.history-table .big-item {
    width: 20%;
    padding: 5px 0px;
}
.history-table .large-item {
    width: 30%;
    padding: 5px 0px;
}
.history-row {
    box-shadow: 0px 0px 0px 3px rgba(68, 138, 255, 1);
    background-color: white;
}
.history-row * {
    border: none !important;
}
.history-loader {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.row-body {
    width: 100%;
}
.header-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: 500;
    padding: 2% 0;
}
.paper-header-cell {
    display: flex;
    flex-direction: row;
}
.table-header {
    justify-content: center;
    display: flex;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    font-weight: 700;
    padding: 20px;
}
.mobile-history-row-content {
    width: 100%;
    padding: 0 !important;
    display: flex;
    flex-direction: row;
}
.mobile-history-row-content > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}
.mobile-history-row-selected {
    border: 1px solid rgb(218, 220, 224);
    background-color: white;
    position: relative;
    z-index: 1;
    box-shadow: 0px 0px 0px 3px rgba(68, 138, 255, 1);
}
.mobile-history-row-not-selected {
    border: 1px solid rgb(218, 220, 224);
    background-color: white;
    position: relative;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.mobile-history-row-expanded {
    margin: 20px 0px;
    transition: margin-top 0.2s ease, margin-bottom 0.2s ease;
}
.mobile-history-row-not-expanded {
    transition: margin-top 0.2s ease, margin-bottom 0.2s ease;
}
.mobile-history-row-table-wrapper {
    width: 90%;
    padding-left: 10%;
}
.comparison-header-row {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2), 0px 0px 2px 0px rgba(0, 0, 0, 0.14), 0px -2px 1px -2px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px 4px 0px 0px !important;
    position: relative;
    margin-top: 20px;
    width: 140px;
    display: flex;
    align-items: center;
}
.comparison-header-cell:first-child {
    padding-right: 10px;
}
.comparison-header-cell {
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.8125rem;
    font-weight: 400;
}
.comparison-header-row-selected {
    margin-top: 20px;
    width: 140px;
    display: flex;
    align-items: center;
    position: relative;
    box-shadow: 3px -3px 0px 0px rgba(68, 138, 255, 1), -3px -3px 0px 0px rgba(68, 138, 255, 1), 0px -1px 5px 0px rgba(0, 0, 0, 0.2),
        0px -2px 2px 0px rgba(0, 0, 0, 0.14), 0px -3px 1px -2px rgba(0, 0, 0, 0.12) !important;
    border-radius: unset !important;
}
.comparison-body {
    border-radius: 0px 4px 4px 4px !important;
}
.comparison-body-selected {
    box-shadow: 0px 0px 0px 3px rgba(68, 138, 255, 1) !important;
    border-radius: unset !important;
}
.history-calculation-comparison-bar{
    position: fixed !important;
}
.comparison-details-color-box {
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 767px) {
    .history-calculation-comparison-bar{
        top: 88px;
    }
}
@media screen and (min-width: 768px) {
    .history-day {
        min-width: 905px;
    }
}

/* @media screen and (max-width: 981px) {
    .history-day {
        width: 90%;
    }
} */
