.dialog-content {
    display: block;
}
.dialog-content .content-wrapper {
    display: flex;
    flex-direction: row;
}
.dialog-content .content-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-right: 1px solid #e8e8e8;
}
.dialog-content .content-side:last-child {
    border-right: none;
    padding-left: 20px;
}

@media screen and (max-width: 850px) {
    .dialog-content .content-wrapper {
        flex-direction: column;
    }
    .dialog-content .content-side {
        width: auto;
        border-right: none;
        border-bottom: 1px solid #e8e8e8;
    }
    .dialog-content .content-side:last-child {
        padding-top: 20px;
        padding-left: 0;
        border-bottom: none;
    }
}
