.input-block {
    display: flex;
    flex-direction: row;
    padding: 10px !important;
}
.input-block input {
    padding-top: 11px;
    padding-bottom: 9px;
}
.input-section {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e8e8e8;
}
.input-section:last-child {
    border-right: none;
}
.input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.input-row.part {
    padding-left: 50px;
}
.input-wrapper {
    display: flex;
    margin: 5px;
}
.input-wrapper.small {
    width: 10%;
}
.input-wrapper.medium {
    width: 20%;
}
.input-wrapper.big {
    width: 30%;
}
.input-wrapper.full {
    width: 100%;
}
.input-check {
    font-size: 100px !important;
    color: lightgray;
}
.input-row .part-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.input-row .part-arrow svg {
    color: lightgray;
    font-size: 30px;
}
.input-container {
    display: flex;
    flex-direction: row;
}
.left-side {
    width: 80%;
}
.right-side {
    width: 20%;
}

.content-side p {
    margin-bottom: 10px;
}
.content-side p:last-child {
    margin-bottom: 0;
}

.check-block-container {
    min-width: 250px;
    margin-left: 10px;
}
.check-block-paper {
    padding: 10px 10px 10px 20px;
    height: 100%;
}
.check-block-header {
    display: flex;
    flex-direction: row;
}
.check-block-header h1 {
    font-weight: 400;
    font-size: 20px;
}
.check-block-item {
    margin-top: 15px;
}
.check-block-item h2 {
    font-weight: 400;
    font-size: 16px;
}
.check-block-item p {
    font-weight: 300;
    font-size: 28px;
}
.check-block-item-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.check-block-icon-button {
    display: none;
}
.check-block-header-row {
    display: flex;
    justify-content: space-between;
}

.grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: 80% 20%;
    grid-template-rows: auto auto auto;
    gap: 1px 1px;
    grid-template-areas: "product start-date" "general applicants" "parts applicants";
}

.applicants {
    grid-area: applicants;
    padding-left: 5px;
}

.general {
    grid-area: general;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
    padding: 5px 0;
}

.parts {
    grid-area: parts;
    border-right: 1px solid lightgray;
}

.product {
    grid-area: product;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
}

.start-date {
    grid-area: start-date;
    border-bottom: 1px solid lightgray;
    padding-left: 5px;
}
.number-of-applicants-label {
    padding: 5px 0;
}
.input-container-parts {
    width: 60%;
    padding-top: 5px;
}
.gen-input {
    margin-left: 15px;
}
@media screen and (max-width: 1367px) {
    .input-container-parts {
        width: 75%;
    }
    .gen-input {
        margin-left: 10px;
    }
}

@media screen and (max-width: 1067px) {
    .input-container-parts {
        width: 80%;
    }
    .gen-input {
        margin-left: 5px;
    }
}
@media screen and (max-width: 767px) {
    .input-container {
        flex-direction: column;
    }
    .input-block {
        flex-direction: column;
    }
    .left-side,
    .right-side {
        width: auto;
    }
    .input-section {
        border-right: none;
        border-bottom: 1px solid #e8e8e8;
    }
    .input-section:last-child {
        border-bottom: none;
    }
    .check-block-container {
        margin-left: 0;
        margin-top: 10px;
    }
    .check-block-icon-button {
        display: block;
    }
}

@media screen and (max-width: 850px) {
    .input-container {
        flex-direction: column;
    }
    .input-block {
        flex-direction: column;
    }
    .input-section {
        border-right: none;
        border-bottom: 1px solid #e8e8e8;
    }
    .check-block-container {
        margin-left: 0;
        margin-top: 10px;
    }
    .input-row {
        flex-direction: column;
    }
    .input-wrapper.small,
    .input-wrapper.medium,
    .input-wrapper.big,
    .input-wrapper.full,
    .left-side,
    .right-side {
        width: auto;
    }
}
