.chart-scroller {
    display: flex !important;
    position: absolute !important;
    right: 20px;
    bottom: 20px;
}
.button-left {
    left: 20px;
}
.button-right {
    right: 20px;
}
