.dom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card {
    display: block;
    width: 95%;
    max-width: 400px;
}
.card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px !important;
}
.sign-input,
.sign-up-button,
.sign-form-input {
    width: 100%;
}
.sign-up-button {
    margin: 16px 0 0 0 !important;
}
.sign-help {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}
.sign-help>button {
    margin-top: 22px;
}
.loader {
    height: 26px !important;
    width: 26px !important;
}
.icon-facebook2 {
    font-size: 28px;
}
.tabs {
    min-width: 0 !important;
}
