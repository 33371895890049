.chart-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}
.chart-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    z-index: 10;
}
.chart-title h1 {
    font-size: 20px;
    font-weight: 400;
    margin-right: 5px;
}
.chart-wrapper {
    width: 100%;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    align-content: center;
    overflow-y: hidden;
    transition: height 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}
.chart-wrapper.chart-loader {
    width: 100%;
    padding: 20px;
    margin-right: 0;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart {
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding: 0px 20px 20px 20px;
    margin-top: -20px;
    overflow-y: auto;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
.totals-container {
    margin-left: 10px;
    min-width: 250px;
}
.totals-comparison-container{
    margin-left: 10px;
    min-width: 500px;
}
.totals-paper {
    width: 100%;
    height: 100%;
    padding: 10px;
    padding-left: 20px;
}
.totals-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.totals-header h1 {
    font-weight: 400;
    font-size: 20px;
}
.totals-item {
    margin-top: 15px;
}
.totals-item:first-child{
    margin-right: 50px;
}
.totals-item-typography{
    text-align: center;
}
.totals-comparison-items {
    display: flex;
    flex-direction: row;
}
.totals-item h2 {
    font-weight: 400;
    font-size: 16px;
}
.totals-item p {
    font-weight: 300;
    font-size: 28px;
}
.monthly-interest-rate-table th,
.monthly-interest-rate-table td {
    padding-top: 5px;
    padding-bottom: 5px;
}
.monthly-interest-rate-table tr {
    height: 35px;
}

@media screen and (max-width: 850px) {
    .chart-container{
        flex-direction: column;
    }
    .totals-container{
        margin-left: 0;
        margin-top: 10px;
    }
    .totals-comparison-container{
        margin-left: 0;
        margin-top: 10px;
    }
}

@media screen and (max-width: 767px) {
    .totals-item-typography{
        text-align: left;
    }
    .totals-comparison-items {
        flex-direction: column;
    }
    .totals-comparison-container{
        min-width: unset;
    }
}