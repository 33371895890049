.dialog {
    width: 40% !important;
}
.filters {
    margin-bottom: 2%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}
.customer-filter-mobile-paper{
    padding: 0 5px;
    width: 80%;
    margin-top: 2%;
    max-width: 320px;
}
.customer-filter-mobile-content{
    display: flex;
    flex-direction: column;
}
.customer-filter-mobile-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wrapper {
    width: 90%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.no-customers-text {
    display: flex;
    justify-content: center;
}
.customer-panel-header-row {
    display: flex;
}
.customer-panel-body-row {
    display: flex;
    flex-direction: row;
}
.customer-panel-body-row-whole-selected {
    box-shadow: 0px 0px 0px 3px rgba(68, 138, 255, 1);
}
div[class*="customer-panel-body-cell"]{
    width: 19%;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: hidden;
    margin-right: 1%;
}
.customer-panel-body-cell-mobile:first-child{
    width: 20% !important;
}
.customer-panel-body-cell-mobile-big{
    width: 35% !important;
}
.customer-panel-body-cell-mobile:last-child{
    width: 15% !important;
}
.customer-panel-body-cell-small {
    width: 9% !important;
}
.customer-panel-body-cell:first-child {
    width: 9% !important;
}
.customer-panel-body-cell:last-child {
    width: 9% !important;
}
.customer-panel-header-cell {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 20%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: 500;
    padding: 1% 0;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.customer-panel-header-cell-small{
    display: flex;
    justify-content: left;
    align-items: center;
    width: 10%;
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: 500;
    padding: 1% 0;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.customer-panel-header-cell:last-child {
    width: 10%;
}
.customer-panel-header-cell:first-child {
    padding: 1% 1%;
    width: 10%;
}
div[class*="customer-panel-header-cell-mobile"]{
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: 500;
    padding: 1% 0;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.customer-panel-header-cell-mobile:first-child{
    padding: 1% 1% !important;
    width: 20%;
}
.customer-panel-header-cell-mobile-big{
    width: 35%;
}
.customer-panel-header-cell-mobile:last-child{
    width: 15%;
}
.customer-panel-body-row-whole {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.customer-panel-body-row-whole-selected * {
    border: none !important;
}
.customer-table {
    width: 100%;
}
.customer-information {
    display: flex;
    flex-direction: column;
}
.customer-information-row {
    justify-content: left;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    align-items: center;
}
.customer-information-cell{
    margin: 20px 0
}
.customer-information-cell:first-child{
    width: 70%;
}
.customer-information-row:first-child {
    margin-top: 2%;
}
.customer-information-radio-button{
    margin-left: 2%;
}
.new-customer-buttons{
    justify-content: space-evenly;
    display: flex;
    width: 70%;
}
.new-customer-radio-question{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.new-customer-radio-question:first-child{
    width: 50%;
}
.consumer-panel-radio-invite{
    margin-top: 32px;
}

@media screen and (max-width: 767px) {
.new-customer-buttons{
    flex-direction: column;
    width: 250px;
}
.new-customer-buttons>button{
    margin-bottom: 12px;
}
}