.dom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card {
    display: block;
    width: 95%;
    max-width: 400px;
}
.card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px !important;
}
.card-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 30px 10px 30px !important;
}
.sign-input,
.sign-in-button,
.sign-form-input {
    width: 100%;
}
.sign-in-button {
    margin: 16px 0 0 0 !important;
}

.sign-help {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}
.sign-help>button {
    margin-top: 22px;
}
.loader {
    height: 26px !important;
    width: 26px !important;
}
.icon-facebook2 {
    font-size: 28px;
}
