.profile-page-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 12px;
}
.profile-page-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 6px;
    width: 100%;
}
.profile-page-cell:first-child {
    width: 15%;
    margin-left: 10%;
    display: flex;
    align-items: center;
}
.profile-page-cell {
    width: 20%;
}

@media screen and (max-width: 767px) {
    .profile-page-row {
        justify-content: unset;
    }
    .profile-page-row:last-child {
        justify-content: center;
    }
    .profile-page-cell:first-child {
        width: 40%;
        margin-left: 2%;
    }
    .profile-page-cell {
        width: 60%;
    }
}
